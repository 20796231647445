// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface BaseGameTyped
 */
export interface BaseGameTyped {
  /**
   *
   * @type {number}
   * @memberof BaseGameTyped
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof BaseGameTyped
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof BaseGameTyped
   */
  logo: string | null;
  /**
   *
   * @type {Date}
   * @memberof BaseGameTyped
   */
  readonly created: Date;
  /**
   *
   * @type {boolean}
   * @memberof BaseGameTyped
   */
  isAuthRequired: boolean;
  /**
   *
   * @type {number}
   * @memberof BaseGameTyped
   */
  readonly discountPercent: number;
  /**
   *
   * @type {string}
   * @memberof BaseGameTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the BaseGameTyped interface.
 */
export function instanceOfBaseGameTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "logo" in value;
  isInstance = isInstance && "created" in value;
  isInstance = isInstance && "isAuthRequired" in value;
  isInstance = isInstance && "discountPercent" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function BaseGameTypedFromJSON(json: any): BaseGameTyped {
  return BaseGameTypedFromJSONTyped(json, false);
}

export function BaseGameTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BaseGameTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    title: json["title"],
    logo: json["logo"],
    created: new Date(json["created"]),
    isAuthRequired: json["is_auth_required"],
    discountPercent: json["discount_percent"],
    resourcetype: json["resourcetype"],
  };
}

export function BaseGameTypedToJSON(value?: BaseGameTyped | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    logo: value.logo,
    is_auth_required: value.isAuthRequired,
    resourcetype: value.resourcetype,
  };
}
