/* tslint:disable */
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  AuthUrl,
  Profile,
  SocialAuthRequest,
  SocialAuthResponse,
  TokenObtainRequest,
  TokenPair,
  TokenRefreshRequest,
} from "../models/index";
import {
  AuthUrlFromJSON,
  AuthUrlToJSON,
  ProfileFromJSON,
  ProfileToJSON,
  SocialAuthRequestFromJSON,
  SocialAuthRequestToJSON,
  SocialAuthResponseFromJSON,
  SocialAuthResponseToJSON,
  TokenObtainRequestFromJSON,
  TokenObtainRequestToJSON,
  TokenPairFromJSON,
  TokenPairToJSON,
  TokenRefreshRequestFromJSON,
  TokenRefreshRequestToJSON,
} from "../models/index";

export interface AuthApiJwtCreateRequest {
  tokenObtainRequest: TokenObtainRequest;
}

export interface AuthApiJwtRefreshRequest {
  tokenRefreshRequest: TokenRefreshRequest;
}

export interface AuthApiSocialFacebookAuthCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialFacebookUrlRetrieveRequest {
  redirectUri: string;
}

export interface AuthApiSocialGoogleAuthCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialGoogleUrlRetrieveRequest {
  redirectUri: string;
}

export interface AuthApiSocialSteamAuthCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialSteamBindCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialSteamUrlRetrieveRequest {
  redirectUri: string;
}

export interface AuthApiSocialTelegramAuthCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialTelegramBindCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialTelegramUrlRetrieveRequest {
  redirectUri: string;
}

export interface AuthApiSocialVkAuthCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialVkBindCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialVkIdAuthCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialVkIdBindCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialVkIdUrlRetrieveRequest {
  redirectUri: string;
}

export interface AuthApiSocialVkUrlRetrieveRequest {
  redirectUri: string;
}

export interface AuthApiSocialYandexAuthCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialYandexBindCreateRequest {
  socialAuthRequest: SocialAuthRequest;
}

export interface AuthApiSocialYandexUrlRetrieveRequest {
  redirectUri: string;
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
  /**
   */
  async jwtCreateRaw(
    requestParameters: AuthApiJwtCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TokenPair>> {
    if (
      requestParameters.tokenObtainRequest === null ||
      requestParameters.tokenObtainRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "tokenObtainRequest",
        "Required parameter requestParameters.tokenObtainRequest was null or undefined when calling jwtCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/jwt/create/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TokenObtainRequestToJSON(requestParameters.tokenObtainRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TokenPairFromJSON(jsonValue),
    );
  }

  /**
   */
  async jwtCreate(
    requestParameters: AuthApiJwtCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TokenPair> {
    const response = await this.jwtCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async jwtRefreshRaw(
    requestParameters: AuthApiJwtRefreshRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TokenPair>> {
    if (
      requestParameters.tokenRefreshRequest === null ||
      requestParameters.tokenRefreshRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "tokenRefreshRequest",
        "Required parameter requestParameters.tokenRefreshRequest was null or undefined when calling jwtRefresh.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/jwt/refresh/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TokenRefreshRequestToJSON(requestParameters.tokenRefreshRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TokenPairFromJSON(jsonValue),
    );
  }

  /**
   */
  async jwtRefresh(
    requestParameters: AuthApiJwtRefreshRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TokenPair> {
    const response = await this.jwtRefreshRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async profileRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Profile>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/profile/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProfileFromJSON(jsonValue),
    );
  }

  /**
   */
  async profileRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Profile> {
    const response = await this.profileRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialFacebookAuthCreateRaw(
    requestParameters: AuthApiSocialFacebookAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SocialAuthResponse>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialFacebookAuthCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/facebook/auth/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SocialAuthResponseFromJSON(jsonValue),
    );
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialFacebookAuthCreate(
    requestParameters: AuthApiSocialFacebookAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SocialAuthResponse> {
    const response = await this.socialFacebookAuthCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialFacebookUnbindCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/facebook/unbind/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialFacebookUnbindCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.socialFacebookUnbindCreateRaw(initOverrides);
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialFacebookUrlRetrieveRaw(
    requestParameters: AuthApiSocialFacebookUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AuthUrl>> {
    if (
      requestParameters.redirectUri === null ||
      requestParameters.redirectUri === undefined
    ) {
      throw new runtime.RequiredError(
        "redirectUri",
        "Required parameter requestParameters.redirectUri was null or undefined when calling socialFacebookUrlRetrieve.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.redirectUri !== undefined) {
      queryParameters["redirect_uri"] = requestParameters.redirectUri;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/facebook/url/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AuthUrlFromJSON(jsonValue),
    );
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialFacebookUrlRetrieve(
    requestParameters: AuthApiSocialFacebookUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AuthUrl> {
    const response = await this.socialFacebookUrlRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialGoogleAuthCreateRaw(
    requestParameters: AuthApiSocialGoogleAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SocialAuthResponse>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialGoogleAuthCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/google/auth/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SocialAuthResponseFromJSON(jsonValue),
    );
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialGoogleAuthCreate(
    requestParameters: AuthApiSocialGoogleAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SocialAuthResponse> {
    const response = await this.socialGoogleAuthCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialGoogleUrlRetrieveRaw(
    requestParameters: AuthApiSocialGoogleUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AuthUrl>> {
    if (
      requestParameters.redirectUri === null ||
      requestParameters.redirectUri === undefined
    ) {
      throw new runtime.RequiredError(
        "redirectUri",
        "Required parameter requestParameters.redirectUri was null or undefined when calling socialGoogleUrlRetrieve.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.redirectUri !== undefined) {
      queryParameters["redirect_uri"] = requestParameters.redirectUri;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/google/url/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AuthUrlFromJSON(jsonValue),
    );
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialGoogleUrlRetrieve(
    requestParameters: AuthApiSocialGoogleUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AuthUrl> {
    const response = await this.socialGoogleUrlRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialSteamAuthCreateRaw(
    requestParameters: AuthApiSocialSteamAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SocialAuthResponse>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialSteamAuthCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/steam/auth/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SocialAuthResponseFromJSON(jsonValue),
    );
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialSteamAuthCreate(
    requestParameters: AuthApiSocialSteamAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SocialAuthResponse> {
    const response = await this.socialSteamAuthCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Привязывает к авторизованному пользователю его профиль в социальной сети
   */
  async socialSteamBindCreateRaw(
    requestParameters: AuthApiSocialSteamBindCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialSteamBindCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/steam/bind/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Привязывает к авторизованному пользователю его профиль в социальной сети
   */
  async socialSteamBindCreate(
    requestParameters: AuthApiSocialSteamBindCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.socialSteamBindCreateRaw(requestParameters, initOverrides);
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialSteamUnbindCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/steam/unbind/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialSteamUnbindCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.socialSteamUnbindCreateRaw(initOverrides);
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialSteamUrlRetrieveRaw(
    requestParameters: AuthApiSocialSteamUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AuthUrl>> {
    if (
      requestParameters.redirectUri === null ||
      requestParameters.redirectUri === undefined
    ) {
      throw new runtime.RequiredError(
        "redirectUri",
        "Required parameter requestParameters.redirectUri was null or undefined when calling socialSteamUrlRetrieve.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.redirectUri !== undefined) {
      queryParameters["redirect_uri"] = requestParameters.redirectUri;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/steam/url/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AuthUrlFromJSON(jsonValue),
    );
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialSteamUrlRetrieve(
    requestParameters: AuthApiSocialSteamUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AuthUrl> {
    const response = await this.socialSteamUrlRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialTelegramAuthCreateRaw(
    requestParameters: AuthApiSocialTelegramAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SocialAuthResponse>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialTelegramAuthCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/telegram/auth/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SocialAuthResponseFromJSON(jsonValue),
    );
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialTelegramAuthCreate(
    requestParameters: AuthApiSocialTelegramAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SocialAuthResponse> {
    const response = await this.socialTelegramAuthCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Привязывает к авторизованному пользователю его профиль в социальной сети
   */
  async socialTelegramBindCreateRaw(
    requestParameters: AuthApiSocialTelegramBindCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialTelegramBindCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/telegram/bind/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Привязывает к авторизованному пользователю его профиль в социальной сети
   */
  async socialTelegramBindCreate(
    requestParameters: AuthApiSocialTelegramBindCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.socialTelegramBindCreateRaw(requestParameters, initOverrides);
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialTelegramUnbindCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/telegram/unbind/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialTelegramUnbindCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.socialTelegramUnbindCreateRaw(initOverrides);
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialTelegramUrlRetrieveRaw(
    requestParameters: AuthApiSocialTelegramUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AuthUrl>> {
    if (
      requestParameters.redirectUri === null ||
      requestParameters.redirectUri === undefined
    ) {
      throw new runtime.RequiredError(
        "redirectUri",
        "Required parameter requestParameters.redirectUri was null or undefined when calling socialTelegramUrlRetrieve.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.redirectUri !== undefined) {
      queryParameters["redirect_uri"] = requestParameters.redirectUri;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/telegram/url/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AuthUrlFromJSON(jsonValue),
    );
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialTelegramUrlRetrieve(
    requestParameters: AuthApiSocialTelegramUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AuthUrl> {
    const response = await this.socialTelegramUrlRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialVkAuthCreateRaw(
    requestParameters: AuthApiSocialVkAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SocialAuthResponse>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialVkAuthCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/vk/auth/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SocialAuthResponseFromJSON(jsonValue),
    );
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialVkAuthCreate(
    requestParameters: AuthApiSocialVkAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SocialAuthResponse> {
    const response = await this.socialVkAuthCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Привязывает к авторизованному пользователю его профиль в социальной сети
   */
  async socialVkBindCreateRaw(
    requestParameters: AuthApiSocialVkBindCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialVkBindCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/vk/bind/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Привязывает к авторизованному пользователю его профиль в социальной сети
   */
  async socialVkBindCreate(
    requestParameters: AuthApiSocialVkBindCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.socialVkBindCreateRaw(requestParameters, initOverrides);
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialVkIdAuthCreateRaw(
    requestParameters: AuthApiSocialVkIdAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SocialAuthResponse>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialVkIdAuthCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/vk_id/auth/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SocialAuthResponseFromJSON(jsonValue),
    );
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialVkIdAuthCreate(
    requestParameters: AuthApiSocialVkIdAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SocialAuthResponse> {
    const response = await this.socialVkIdAuthCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Привязывает к авторизованному пользователю его профиль в социальной сети
   */
  async socialVkIdBindCreateRaw(
    requestParameters: AuthApiSocialVkIdBindCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialVkIdBindCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/vk_id/bind/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Привязывает к авторизованному пользователю его профиль в социальной сети
   */
  async socialVkIdBindCreate(
    requestParameters: AuthApiSocialVkIdBindCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.socialVkIdBindCreateRaw(requestParameters, initOverrides);
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialVkIdUnbindCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/vk_id/unbind/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialVkIdUnbindCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.socialVkIdUnbindCreateRaw(initOverrides);
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialVkIdUrlRetrieveRaw(
    requestParameters: AuthApiSocialVkIdUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AuthUrl>> {
    if (
      requestParameters.redirectUri === null ||
      requestParameters.redirectUri === undefined
    ) {
      throw new runtime.RequiredError(
        "redirectUri",
        "Required parameter requestParameters.redirectUri was null or undefined when calling socialVkIdUrlRetrieve.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.redirectUri !== undefined) {
      queryParameters["redirect_uri"] = requestParameters.redirectUri;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/vk_id/url/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AuthUrlFromJSON(jsonValue),
    );
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialVkIdUrlRetrieve(
    requestParameters: AuthApiSocialVkIdUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AuthUrl> {
    const response = await this.socialVkIdUrlRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialVkUnbindCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/vk/unbind/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialVkUnbindCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.socialVkUnbindCreateRaw(initOverrides);
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialVkUrlRetrieveRaw(
    requestParameters: AuthApiSocialVkUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AuthUrl>> {
    if (
      requestParameters.redirectUri === null ||
      requestParameters.redirectUri === undefined
    ) {
      throw new runtime.RequiredError(
        "redirectUri",
        "Required parameter requestParameters.redirectUri was null or undefined when calling socialVkUrlRetrieve.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.redirectUri !== undefined) {
      queryParameters["redirect_uri"] = requestParameters.redirectUri;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/vk/url/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AuthUrlFromJSON(jsonValue),
    );
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialVkUrlRetrieve(
    requestParameters: AuthApiSocialVkUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AuthUrl> {
    const response = await this.socialVkUrlRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialYandexAuthCreateRaw(
    requestParameters: AuthApiSocialYandexAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SocialAuthResponse>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialYandexAuthCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/yandex/auth/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SocialAuthResponseFromJSON(jsonValue),
    );
  }

  /**
   * Авторизовывает пользователя по его профилю в социальной сети
   */
  async socialYandexAuthCreate(
    requestParameters: AuthApiSocialYandexAuthCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SocialAuthResponse> {
    const response = await this.socialYandexAuthCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Привязывает к авторизованному пользователю его профиль в социальной сети
   */
  async socialYandexBindCreateRaw(
    requestParameters: AuthApiSocialYandexBindCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.socialAuthRequest === null ||
      requestParameters.socialAuthRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "socialAuthRequest",
        "Required parameter requestParameters.socialAuthRequest was null or undefined when calling socialYandexBindCreate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/yandex/bind/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SocialAuthRequestToJSON(requestParameters.socialAuthRequest),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Привязывает к авторизованному пользователю его профиль в социальной сети
   */
  async socialYandexBindCreate(
    requestParameters: AuthApiSocialYandexBindCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.socialYandexBindCreateRaw(requestParameters, initOverrides);
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialYandexUnbindCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/yandex/unbind/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Отвязывает пользователя от его профиля в социальной сети
   */
  async socialYandexUnbindCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.socialYandexUnbindCreateRaw(initOverrides);
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialYandexUrlRetrieveRaw(
    requestParameters: AuthApiSocialYandexUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AuthUrl>> {
    if (
      requestParameters.redirectUri === null ||
      requestParameters.redirectUri === undefined
    ) {
      throw new runtime.RequiredError(
        "redirectUri",
        "Required parameter requestParameters.redirectUri was null or undefined when calling socialYandexUrlRetrieve.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.redirectUri !== undefined) {
      queryParameters["redirect_uri"] = requestParameters.redirectUri;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/auth/social/yandex/url/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AuthUrlFromJSON(jsonValue),
    );
  }

  /**
   * Возвращает URL, на который необходимо перенаправить пользователя для авторизации
   */
  async socialYandexUrlRetrieve(
    requestParameters: AuthApiSocialYandexUrlRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AuthUrl> {
    const response = await this.socialYandexUrlRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
