// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `Gold` - Gold
 * * `Star` - Star
 * @export
 */
export const IconEnum = {
  Gold: "Gold",
  Star: "Star",
} as const;
export type IconEnum = (typeof IconEnum)[keyof typeof IconEnum];

export function IconEnumFromJSON(json: any): IconEnum {
  return IconEnumFromJSONTyped(json, false);
}

export function IconEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): IconEnum {
  return json as IconEnum;
}

export function IconEnumToJSON(value?: IconEnum | null): any {
  return value as any;
}
