// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface StandoffOrderShort
 */
export interface StandoffOrderShort {
  /**
   *
   * @type {number}
   * @memberof StandoffOrderShort
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof StandoffOrderShort
   */
  gameAccountId: string;
  /**
   *
   * @type {string}
   * @memberof StandoffOrderShort
   */
  gameAvatar: string | null;
  /**
   *
   * @type {string}
   * @memberof StandoffOrderShort
   */
  total: string;
}

/**
 * Check if a given object implements the StandoffOrderShort interface.
 */
export function instanceOfStandoffOrderShort(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "gameAccountId" in value;
  isInstance = isInstance && "gameAvatar" in value;
  isInstance = isInstance && "total" in value;

  return isInstance;
}

export function StandoffOrderShortFromJSON(json: any): StandoffOrderShort {
  return StandoffOrderShortFromJSONTyped(json, false);
}

export function StandoffOrderShortFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StandoffOrderShort {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    gameAccountId: json["game_account_id"],
    gameAvatar: json["game_avatar"],
    total: json["total"],
  };
}

export function StandoffOrderShortToJSON(
  value?: StandoffOrderShort | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    game_account_id: value.gameAccountId,
    game_avatar: value.gameAvatar,
    total: value.total,
  };
}
