<template>
  <h1 :class="$style['heading-1']">
    <slot />
  </h1>
</template>

<style lang="scss" module>
.heading-1 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -3%;

  @media screen and (max-width: $tablet) {
    font-size: 3rem;
    line-height: 105%;
  }

  @media screen and (max-width: $mobile) {
    font-size: 2rem;
    line-height: 120%;
    letter-spacing: -2%;
  }
}
</style>
