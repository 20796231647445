<template>
  <div :class="$style.toast">
    <div :class="$style.icon">
      <IconCross height="12px" />
    </div>
    <p :class="$style.text"><slot /></p>

    <div :class="$style.iconClose">
      <IconCross
        height="16px"
        @click="$emit('animationend')"
      />
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style module lang="scss">
.toast {
  display: flex;
  align-items: center;
  border-radius: var(--radius-8xl);
  background-color: var(--general-transparent-mid-25);
  backdrop-filter: blur(20px);
  gap: var(--spacing-md);
  padding: var(--spacing-2lg) var(--spacing-2xl) var(--spacing-2lg)
    var(--spacing-2lg);

  .iconClose {
    width: 16px;
    height: 16px;
    opacity: 0.35;
    cursor: pointer;
  }

  .icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--optional-red);
    border-radius: var(--radius-8xl);
  }
  .text {
    padding: 0;
  }
}
</style>
