/* tslint:disable */
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type { BaseProduct, PaginatedBaseProductList } from "../models/index";
import {
  BaseProductFromJSON,
  BaseProductToJSON,
  PaginatedBaseProductListFromJSON,
  PaginatedBaseProductListToJSON,
} from "../models/index";

export interface ProductsApiListRequest {
  page?: number;
  pageSize?: number;
}

export interface ProductsApiRetrieveRequest {
  id: number;
}

/**
 *
 */
export class ProductsApi extends runtime.BaseAPI {
  /**
   * Список всех товаров
   */
  async listRaw(
    requestParameters: ProductsApiListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBaseProductList>> {
    const queryParameters: any = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/products/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBaseProductListFromJSON(jsonValue),
    );
  }

  /**
   * Список всех товаров
   */
  async list(
    requestParameters: ProductsApiListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBaseProductList> {
    const response = await this.listRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Выбранный товар
   */
  async retrieveRaw(
    requestParameters: ProductsApiRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BaseProduct>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling retrieve.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/products/{id}/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BaseProductFromJSON(jsonValue),
    );
  }

  /**
   * Выбранный товар
   */
  async retrieve(
    requestParameters: ProductsApiRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BaseProduct> {
    const response = await this.retrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
