import revive_payload_client_cX7F5W1s6W from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.0_s_xkv2tgcoudm6v6vqlxgqg2crju/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fpbIUwDgib from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.0_s_xkv2tgcoudm6v6vqlxgqg2crju/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5e5XEmCdqN from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.0_s_xkv2tgcoudm6v6vqlxgqg2crju/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5otvYMO9ME from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.0_s_xkv2tgcoudm6v6vqlxgqg2crju/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_0kzOcicYLB from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.0_s_xkv2tgcoudm6v6vqlxgqg2crju/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_shRBqzOiQ4 from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.0_typescript@5.3.3_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_4Td6J6EaAb from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.0_s_xkv2tgcoudm6v6vqlxgqg2crju/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_Sfp0GyfHLb from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.14.0_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_41fsr2XC66 from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.0_s_xkv2tgcoudm6v6vqlxgqg2crju/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_gen_YFjFjUkJWh from "/app/plugins/api-gen.ts";
import app_error_logger_MPDClJPsYE from "/app/plugins/app-error-logger.ts";
import chunk_errors_client_tdDLVWH9ke from "/app/plugins/chunk-errors.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import tooltip_client_Mj8x1saCmV from "/app/plugins/tooltip.client.ts";
import vue_error_logger_VqRcc6CYdh from "/app/plugins/vue-error-logger.ts";
export default [
  revive_payload_client_cX7F5W1s6W,
  unhead_fpbIUwDgib,
  router_5e5XEmCdqN,
  payload_client_5otvYMO9ME,
  check_outdated_build_client_0kzOcicYLB,
  plugin_vue3_shRBqzOiQ4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4Td6J6EaAb,
  i18n_Sfp0GyfHLb,
  chunk_reload_client_41fsr2XC66,
  api_gen_YFjFjUkJWh,
  app_error_logger_MPDClJPsYE,
  chunk_errors_client_tdDLVWH9ke,
  sentry_client_shVUlIjFLk,
  tooltip_client_Mj8x1saCmV,
  vue_error_logger_VqRcc6CYdh
]