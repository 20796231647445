// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface VoucherCode
 */
export interface VoucherCode {
  /**
   *
   * @type {string}
   * @memberof VoucherCode
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof VoucherCode
   */
  amount: string;
}

/**
 * Check if a given object implements the VoucherCode interface.
 */
export function instanceOfVoucherCode(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "code" in value;
  isInstance = isInstance && "amount" in value;

  return isInstance;
}

export function VoucherCodeFromJSON(json: any): VoucherCode {
  return VoucherCodeFromJSONTyped(json, false);
}

export function VoucherCodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): VoucherCode {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json["code"],
    amount: json["amount"],
  };
}

export function VoucherCodeToJSON(value?: VoucherCode | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    amount: value.amount,
  };
}
