// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { OrderGame } from "./OrderGame";
import {
  OrderGameFromJSON,
  OrderGameFromJSONTyped,
  OrderGameToJSON,
} from "./OrderGame";
import type { StatusEnum } from "./StatusEnum";
import {
  StatusEnumFromJSON,
  StatusEnumFromJSONTyped,
  StatusEnumToJSON,
} from "./StatusEnum";
import type { VoucherOrderProduct } from "./VoucherOrderProduct";
import {
  VoucherOrderProductFromJSON,
  VoucherOrderProductFromJSONTyped,
  VoucherOrderProductToJSON,
} from "./VoucherOrderProduct";

/**
 *
 * @export
 * @interface VoucherOrderHistoryTyped
 */
export interface VoucherOrderHistoryTyped {
  /**
   *
   * @type {number}
   * @memberof VoucherOrderHistoryTyped
   */
  readonly id: number;
  /**
   *
   * @type {StatusEnum}
   * @memberof VoucherOrderHistoryTyped
   */
  status: StatusEnum;
  /**
   *
   * @type {Date}
   * @memberof VoucherOrderHistoryTyped
   */
  readonly created: Date;
  /**
   *
   * @type {string}
   * @memberof VoucherOrderHistoryTyped
   */
  total: string;
  /**
   *
   * @type {string}
   * @memberof VoucherOrderHistoryTyped
   */
  rejectReason: string;
  /**
   *
   * @type {Array<VoucherOrderProduct>}
   * @memberof VoucherOrderHistoryTyped
   */
  orderProducts: Array<VoucherOrderProduct>;
  /**
   *
   * @type {string}
   * @memberof VoucherOrderHistoryTyped
   */
  readonly mostExpensiveProductImage: string;
  /**
   *
   * @type {OrderGame}
   * @memberof VoucherOrderHistoryTyped
   */
  readonly game: OrderGame;
  /**
   *
   * @type {string}
   * @memberof VoucherOrderHistoryTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the VoucherOrderHistoryTyped interface.
 */
export function instanceOfVoucherOrderHistoryTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "created" in value;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "rejectReason" in value;
  isInstance = isInstance && "orderProducts" in value;
  isInstance = isInstance && "mostExpensiveProductImage" in value;
  isInstance = isInstance && "game" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function VoucherOrderHistoryTypedFromJSON(
  json: any,
): VoucherOrderHistoryTyped {
  return VoucherOrderHistoryTypedFromJSONTyped(json, false);
}

export function VoucherOrderHistoryTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): VoucherOrderHistoryTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    status: StatusEnumFromJSON(json["status"]),
    created: new Date(json["created"]),
    total: json["total"],
    rejectReason: json["reject_reason"],
    orderProducts: (json["order_products"] as Array<any>).map(
      VoucherOrderProductFromJSON,
    ),
    mostExpensiveProductImage: json["most_expensive_product_image"],
    game: OrderGameFromJSON(json["game"]),
    resourcetype: json["resourcetype"],
  };
}

export function VoucherOrderHistoryTypedToJSON(
  value?: VoucherOrderHistoryTyped | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: StatusEnumToJSON(value.status),
    total: value.total,
    reject_reason: value.rejectReason,
    order_products: (value.orderProducts as Array<any>).map(
      VoucherOrderProductToJSON,
    ),
    resourcetype: value.resourcetype,
  };
}
