// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { OrderItemRequest } from "./OrderItemRequest";
import {
  OrderItemRequestFromJSON,
  OrderItemRequestFromJSONTyped,
  OrderItemRequestToJSON,
} from "./OrderItemRequest";

/**
 *
 * @export
 * @interface VoucherOrderRequest
 */
export interface VoucherOrderRequest {
  /**
   *
   * @type {Array<OrderItemRequest>}
   * @memberof VoucherOrderRequest
   */
  products: Array<OrderItemRequest>;
  /**
   *
   * @type {string}
   * @memberof VoucherOrderRequest
   */
  email?: string;
}

/**
 * Check if a given object implements the VoucherOrderRequest interface.
 */
export function instanceOfVoucherOrderRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "products" in value;

  return isInstance;
}

export function VoucherOrderRequestFromJSON(json: any): VoucherOrderRequest {
  return VoucherOrderRequestFromJSONTyped(json, false);
}

export function VoucherOrderRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): VoucherOrderRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    products: (json["products"] as Array<any>).map(OrderItemRequestFromJSON),
    email: !exists(json, "email") ? undefined : json["email"],
  };
}

export function VoucherOrderRequestToJSON(
  value?: VoucherOrderRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    products: (value.products as Array<any>).map(OrderItemRequestToJSON),
    email: value.email,
  };
}
