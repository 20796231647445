/* tslint:disable */
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type { ActivatePromoCodeRequest, PromoCode } from "../models/index";
import {
  ActivatePromoCodeRequestFromJSON,
  ActivatePromoCodeRequestToJSON,
  PromoCodeFromJSON,
  PromoCodeToJSON,
} from "../models/index";

export interface PromoCodesApiCodesActivateRequest {
  activatePromoCodeRequest: ActivatePromoCodeRequest;
}

export interface PromoCodesApiCodesShowRequest {
  activatePromoCodeRequest: ActivatePromoCodeRequest;
}

/**
 *
 */
export class PromoCodesApi extends runtime.BaseAPI {
  /**
   */
  async codesActivateRaw(
    requestParameters: PromoCodesApiCodesActivateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PromoCode>> {
    if (
      requestParameters.activatePromoCodeRequest === null ||
      requestParameters.activatePromoCodeRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "activatePromoCodeRequest",
        "Required parameter requestParameters.activatePromoCodeRequest was null or undefined when calling codesActivate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/promo_codes/activate/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ActivatePromoCodeRequestToJSON(
          requestParameters.activatePromoCodeRequest,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromoCodeFromJSON(jsonValue),
    );
  }

  /**
   */
  async codesActivate(
    requestParameters: PromoCodesApiCodesActivateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PromoCode> {
    const response = await this.codesActivateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async codesDeactivateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/promo_codes/deactivate/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async codesDeactivate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.codesDeactivateRaw(initOverrides);
  }

  /**
   */
  async codesShowRaw(
    requestParameters: PromoCodesApiCodesShowRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PromoCode>> {
    if (
      requestParameters.activatePromoCodeRequest === null ||
      requestParameters.activatePromoCodeRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "activatePromoCodeRequest",
        "Required parameter requestParameters.activatePromoCodeRequest was null or undefined when calling codesShow.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/promo_codes/show/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ActivatePromoCodeRequestToJSON(
          requestParameters.activatePromoCodeRequest,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PromoCodeFromJSON(jsonValue),
    );
  }

  /**
   */
  async codesShow(
    requestParameters: PromoCodesApiCodesShowRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PromoCode> {
    const response = await this.codesShowRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
