// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `created` - Создан
 * * `paid` - Оплачен
 * * `sent` - Отправлен
 * * `done` - Выполнен
 * * `failed` - Неуспешный
 * @export
 */
export const StatusEnum = {
  Created: "created",
  Paid: "paid",
  Sent: "sent",
  Done: "done",
  Failed: "failed",
} as const;
export type StatusEnum = (typeof StatusEnum)[keyof typeof StatusEnum];

export function StatusEnumFromJSON(json: any): StatusEnum {
  return StatusEnumFromJSONTyped(json, false);
}

export function StatusEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StatusEnum {
  return json as StatusEnum;
}

export function StatusEnumToJSON(value?: StatusEnum | null): any {
  return value as any;
}
