// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  BaseGameDetailTyped,
  instanceOfBaseGameDetailTyped,
  BaseGameDetailTypedFromJSON,
  BaseGameDetailTypedFromJSONTyped,
  BaseGameDetailTypedToJSON,
} from "./BaseGameDetailTyped";
import {
  BaseGameTyped,
  instanceOfBaseGameTyped,
  BaseGameTypedFromJSON,
  BaseGameTypedFromJSONTyped,
  BaseGameTypedToJSON,
} from "./BaseGameTyped";
import {
  MooGoldGameDetailTyped,
  instanceOfMooGoldGameDetailTyped,
  MooGoldGameDetailTypedFromJSON,
  MooGoldGameDetailTypedFromJSONTyped,
  MooGoldGameDetailTypedToJSON,
} from "./MooGoldGameDetailTyped";

/**
 * @type Game
 *
 * @export
 */
export type Game =
  | ({ resourcetype: "BulldropGame" } & BaseGameDetailTyped)
  | ({ resourcetype: "Game" } & BaseGameTyped)
  | ({ resourcetype: "PUBGGame" } & BaseGameDetailTyped)
  | ({ resourcetype: "StandoffGame" } & BaseGameDetailTyped)
  | ({ resourcetype: "SupplyGame" } & MooGoldGameDetailTyped);

export function GameFromJSON(json: any): Game {
  return GameFromJSONTyped(json, false);
}

export function GameFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Game {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json["resourcetype"]) {
    case "BulldropGame":
      return {
        ...BaseGameDetailTypedFromJSONTyped(json, true),
        resourcetype: "BulldropGame",
      };
    case "Game":
      return {
        ...BaseGameTypedFromJSONTyped(json, true),
        resourcetype: "Game",
      };
    case "PUBGGame":
      return {
        ...BaseGameDetailTypedFromJSONTyped(json, true),
        resourcetype: "PUBGGame",
      };
    case "StandoffGame":
      return {
        ...BaseGameDetailTypedFromJSONTyped(json, true),
        resourcetype: "StandoffGame",
      };
    case "SupplyGame":
      return {
        ...MooGoldGameDetailTypedFromJSONTyped(json, true),
        resourcetype: "SupplyGame",
      };
    default:
      throw new Error(
        `No variant of Game exists with 'resourcetype=${json["resourcetype"]}'`,
      );
  }
}

export function GameToJSON(value?: Game | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value["resourcetype"]) {
    case "BulldropGame":
      return BaseGameDetailTypedToJSON(value);
    case "Game":
      return BaseGameTypedToJSON(value);
    case "PUBGGame":
      return BaseGameDetailTypedToJSON(value);
    case "StandoffGame":
      return BaseGameDetailTypedToJSON(value);
    case "SupplyGame":
      return MooGoldGameDetailTypedToJSON(value);
    default:
      throw new Error(
        `No variant of Game exists with 'resourcetype=${value["resourcetype"]}'`,
      );
  }
}
