// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { IconEnum } from "./IconEnum";
import {
  IconEnumFromJSON,
  IconEnumFromJSONTyped,
  IconEnumToJSON,
} from "./IconEnum";

/**
 *
 * @export
 * @interface BaseProduct
 */
export interface BaseProduct {
  /**
   *
   * @type {number}
   * @memberof BaseProduct
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof BaseProduct
   */
  title: string;
  /**
   *
   * @type {IconEnum}
   * @memberof BaseProduct
   */
  icon: IconEnum;
  /**
   *
   * @type {string}
   * @memberof BaseProduct
   */
  price: string;
  /**
   *
   * @type {string}
   * @memberof BaseProduct
   */
  readonly discountPrice: string;
  /**
   *
   * @type {number}
   * @memberof BaseProduct
   */
  readonly discountPercent: number;
  /**
   *
   * @type {string}
   * @memberof BaseProduct
   */
  picture: string | null;
  /**
   *
   * @type {number}
   * @memberof BaseProduct
   */
  minQuantity: number;
  /**
   *
   * @type {number}
   * @memberof BaseProduct
   */
  maxQuantity: number;
  /**
   *
   * @type {string}
   * @memberof BaseProduct
   */
  description: string | null;
}

/**
 * Check if a given object implements the BaseProduct interface.
 */
export function instanceOfBaseProduct(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "icon" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "discountPrice" in value;
  isInstance = isInstance && "discountPercent" in value;
  isInstance = isInstance && "picture" in value;
  isInstance = isInstance && "minQuantity" in value;
  isInstance = isInstance && "maxQuantity" in value;
  isInstance = isInstance && "description" in value;

  return isInstance;
}

export function BaseProductFromJSON(json: any): BaseProduct {
  return BaseProductFromJSONTyped(json, false);
}

export function BaseProductFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BaseProduct {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    title: json["title"],
    icon: IconEnumFromJSON(json["icon"]),
    price: json["price"],
    discountPrice: json["discount_price"],
    discountPercent: json["discount_percent"],
    picture: json["picture"],
    minQuantity: json["min_quantity"],
    maxQuantity: json["max_quantity"],
    description: json["description"],
  };
}

export function BaseProductToJSON(value?: BaseProduct | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    icon: IconEnumToJSON(value.icon),
    price: value.price,
    picture: value.picture,
    min_quantity: value.minQuantity,
    max_quantity: value.maxQuantity,
    description: value.description,
  };
}
