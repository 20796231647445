<template>
  <svg
    viewBox="0 0 3 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.4913 9.86209C0.871079 9.86209 0.357179 9.38363 0.339459 8.72797L0.233135 1.85235C0.215414 1.19668 0.782476 0.541016 1.4913 0.541016C2.21785 0.541016 2.76719 1.19668 2.74947 1.85235L2.64315 8.72797C2.62543 9.40135 2.09381 9.86209 1.4913 9.86209ZM1.50902 13.4594C0.747034 13.4594 0.144531 12.8569 0.144531 12.0949C0.144531 11.3506 0.764755 10.7481 1.50902 10.7481C2.25329 10.7481 2.85579 11.3506 2.85579 12.0949C2.85579 12.8569 2.25329 13.4594 1.50902 13.4594Z"
      fill="white"
    />
  </svg>
</template>
