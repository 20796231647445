// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ActivatePromoCodeRequest
 */
export interface ActivatePromoCodeRequest {
  /**
   *
   * @type {string}
   * @memberof ActivatePromoCodeRequest
   */
  code: string;
}

/**
 * Check if a given object implements the ActivatePromoCodeRequest interface.
 */
export function instanceOfActivatePromoCodeRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "code" in value;

  return isInstance;
}

export function ActivatePromoCodeRequestFromJSON(
  json: any,
): ActivatePromoCodeRequest {
  return ActivatePromoCodeRequestFromJSONTyped(json, false);
}

export function ActivatePromoCodeRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivatePromoCodeRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json["code"],
  };
}

export function ActivatePromoCodeRequestToJSON(
  value?: ActivatePromoCodeRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
  };
}
