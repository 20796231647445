// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { OrderGame } from "./OrderGame";
import {
  OrderGameFromJSON,
  OrderGameFromJSONTyped,
  OrderGameToJSON,
} from "./OrderGame";
import type { OrderProduct } from "./OrderProduct";
import {
  OrderProductFromJSON,
  OrderProductFromJSONTyped,
  OrderProductToJSON,
} from "./OrderProduct";
import type { StatusEnum } from "./StatusEnum";
import {
  StatusEnumFromJSON,
  StatusEnumFromJSONTyped,
  StatusEnumToJSON,
} from "./StatusEnum";

/**
 *
 * @export
 * @interface BaseOrderHistoryTyped
 */
export interface BaseOrderHistoryTyped {
  /**
   *
   * @type {number}
   * @memberof BaseOrderHistoryTyped
   */
  readonly id: number;
  /**
   *
   * @type {StatusEnum}
   * @memberof BaseOrderHistoryTyped
   */
  status: StatusEnum;
  /**
   *
   * @type {Date}
   * @memberof BaseOrderHistoryTyped
   */
  readonly created: Date;
  /**
   *
   * @type {string}
   * @memberof BaseOrderHistoryTyped
   */
  total: string;
  /**
   *
   * @type {string}
   * @memberof BaseOrderHistoryTyped
   */
  rejectReason: string;
  /**
   *
   * @type {Array<OrderProduct>}
   * @memberof BaseOrderHistoryTyped
   */
  orderProducts: Array<OrderProduct>;
  /**
   *
   * @type {string}
   * @memberof BaseOrderHistoryTyped
   */
  readonly mostExpensiveProductImage: string;
  /**
   *
   * @type {OrderGame}
   * @memberof BaseOrderHistoryTyped
   */
  readonly game: OrderGame;
  /**
   *
   * @type {string}
   * @memberof BaseOrderHistoryTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the BaseOrderHistoryTyped interface.
 */
export function instanceOfBaseOrderHistoryTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "created" in value;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "rejectReason" in value;
  isInstance = isInstance && "orderProducts" in value;
  isInstance = isInstance && "mostExpensiveProductImage" in value;
  isInstance = isInstance && "game" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function BaseOrderHistoryTypedFromJSON(
  json: any,
): BaseOrderHistoryTyped {
  return BaseOrderHistoryTypedFromJSONTyped(json, false);
}

export function BaseOrderHistoryTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BaseOrderHistoryTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    status: StatusEnumFromJSON(json["status"]),
    created: new Date(json["created"]),
    total: json["total"],
    rejectReason: json["reject_reason"],
    orderProducts: (json["order_products"] as Array<any>).map(
      OrderProductFromJSON,
    ),
    mostExpensiveProductImage: json["most_expensive_product_image"],
    game: OrderGameFromJSON(json["game"]),
    resourcetype: json["resourcetype"],
  };
}

export function BaseOrderHistoryTypedToJSON(
  value?: BaseOrderHistoryTyped | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: StatusEnumToJSON(value.status),
    total: value.total,
    reject_reason: value.rejectReason,
    order_products: (value.orderProducts as Array<any>).map(OrderProductToJSON),
    resourcetype: value.resourcetype,
  };
}
