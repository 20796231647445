// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ActivateCodeResponse
 */
export interface ActivateCodeResponse {
  /**
   *
   * @type {string}
   * @memberof ActivateCodeResponse
   */
  amount: string;
}

/**
 * Check if a given object implements the ActivateCodeResponse interface.
 */
export function instanceOfActivateCodeResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "amount" in value;

  return isInstance;
}

export function ActivateCodeResponseFromJSON(json: any): ActivateCodeResponse {
  return ActivateCodeResponseFromJSONTyped(json, false);
}

export function ActivateCodeResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivateCodeResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amount: json["amount"],
  };
}

export function ActivateCodeResponseToJSON(
  value?: ActivateCodeResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount: value.amount,
  };
}
