// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { OrderGame } from "./OrderGame";
import {
  OrderGameFromJSON,
  OrderGameFromJSONTyped,
  OrderGameToJSON,
} from "./OrderGame";
import type { PubgOrderProduct } from "./PubgOrderProduct";
import {
  PubgOrderProductFromJSON,
  PubgOrderProductFromJSONTyped,
  PubgOrderProductToJSON,
} from "./PubgOrderProduct";
import type { StatusEnum } from "./StatusEnum";
import {
  StatusEnumFromJSON,
  StatusEnumFromJSONTyped,
  StatusEnumToJSON,
} from "./StatusEnum";

/**
 *
 * @export
 * @interface PUBGOrderHistoryTyped
 */
export interface PUBGOrderHistoryTyped {
  /**
   *
   * @type {number}
   * @memberof PUBGOrderHistoryTyped
   */
  readonly id: number;
  /**
   *
   * @type {StatusEnum}
   * @memberof PUBGOrderHistoryTyped
   */
  status: StatusEnum;
  /**
   *
   * @type {Date}
   * @memberof PUBGOrderHistoryTyped
   */
  readonly created: Date;
  /**
   *
   * @type {string}
   * @memberof PUBGOrderHistoryTyped
   */
  total: string;
  /**
   *
   * @type {string}
   * @memberof PUBGOrderHistoryTyped
   */
  rejectReason: string;
  /**
   *
   * @type {Array<PubgOrderProduct>}
   * @memberof PUBGOrderHistoryTyped
   */
  orderProducts: Array<PubgOrderProduct>;
  /**
   *
   * @type {string}
   * @memberof PUBGOrderHistoryTyped
   */
  readonly mostExpensiveProductImage: string;
  /**
   *
   * @type {OrderGame}
   * @memberof PUBGOrderHistoryTyped
   */
  readonly game: OrderGame;
  /**
   *
   * @type {string}
   * @memberof PUBGOrderHistoryTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the PUBGOrderHistoryTyped interface.
 */
export function instanceOfPUBGOrderHistoryTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "created" in value;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "rejectReason" in value;
  isInstance = isInstance && "orderProducts" in value;
  isInstance = isInstance && "mostExpensiveProductImage" in value;
  isInstance = isInstance && "game" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function PUBGOrderHistoryTypedFromJSON(
  json: any,
): PUBGOrderHistoryTyped {
  return PUBGOrderHistoryTypedFromJSONTyped(json, false);
}

export function PUBGOrderHistoryTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PUBGOrderHistoryTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    status: StatusEnumFromJSON(json["status"]),
    created: new Date(json["created"]),
    total: json["total"],
    rejectReason: json["reject_reason"],
    orderProducts: (json["order_products"] as Array<any>).map(
      PubgOrderProductFromJSON,
    ),
    mostExpensiveProductImage: json["most_expensive_product_image"],
    game: OrderGameFromJSON(json["game"]),
    resourcetype: json["resourcetype"],
  };
}

export function PUBGOrderHistoryTypedToJSON(
  value?: PUBGOrderHistoryTyped | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: StatusEnumToJSON(value.status),
    total: value.total,
    reject_reason: value.rejectReason,
    order_products: (value.orderProducts as Array<any>).map(
      PubgOrderProductToJSON,
    ),
    resourcetype: value.resourcetype,
  };
}
