<template>
  <SharedToastSuccessToast
    v-if="notificationCopy?.type === 'success'"
    :class="[$style.notification, countdown && $style.animate]"
    @animationend="handleAnimationEnd"
    @handle-close="handleAnimationEnd"
  >
    {{ notificationCopy?.text }}
  </SharedToastSuccessToast>
  <SharedToastWarningToast
    v-else-if="notificationCopy?.type === 'warning'"
    :class="[$style.notification, countdown && $style.animate]"
    @animationend="handleAnimationEnd"
    @handle-close="handleAnimationEnd"
  >
    {{ notificationCopy?.text }}
  </SharedToastWarningToast>
  <SharedToastErrorToast
    v-else-if="notificationCopy?.type === 'error'"
    :class="[$style.notification, countdown && $style.animate]"
    @animationend="handleAnimationEnd"
    @handle-close="handleAnimationEnd"
  >
    {{ notificationCopy?.text }}
  </SharedToastErrorToast>
</template>

<script setup lang="ts">
import { nextTick, onBeforeMount, onMounted, ref, watch } from "vue";
import type { INotificationOptions } from "~/types/notifications";

const notificationsStore = useNotificationsStore();
const notificationCopy = ref<INotificationOptions | undefined>(undefined);
const countdown = ref(false);
const stack = ref<INotificationOptions[]>([]);
let timer: ReturnType<typeof setTimeout> | undefined;

watch(
  () => notificationsStore.notification,
  (newNotification: INotificationOptions | undefined) => {
    if (!newNotification) return;
    clearTimeout(timer);
    if (
      notificationCopy.value &&
      notificationCopy.value.text === newNotification.text &&
      notificationCopy.value.type === newNotification.type
    ) {
      countdown.value = false;
      timer = setTimeout(() => (countdown.value = true), 10);
    } else {
      stack.value.push(JSON.parse(JSON.stringify(newNotification)));
    }
  },
);

onMounted(() => {
  notificationCopy.value = JSON.parse(
    JSON.stringify(notificationsStore.notification),
  );
  nextTick(() => (countdown.value = true));
});

onBeforeMount(() => clearTimeout(timer));

const handleAnimationEnd = () => {
  if (stack.value.length) {
    const copiedValue = stack.value.shift();
    if (!copiedValue) return;
    notificationCopy.value = copiedValue;
    countdown.value = false;
    timer = setTimeout(() => (countdown.value = true), 10);
  } else {
    notificationsStore.hideNotification();
  }
};
</script>

<style lang="scss" module>
.notification {
  position: fixed;
  right: 15px;
  top: 11dvh;
  z-index: 9999;
  min-width: 300px;

  @media screen and (max-width: 500px) {
    right: 50%;
    transform: translateX(50%);
  }

  @media screen and (max-width: 450px) {
    width: auto;
    top: 9dvh;
  }
}
.animate {
  animation: disappear-animation 2s 3s ease forwards;

  &:hover {
    animation: none;
  }
}

@keyframes disappear-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
