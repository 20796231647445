<template>
  <svg
    viewBox="0 0 15 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6089 0.248456C14.2081 0.676463 14.3469 1.50919 13.9189 2.1084L7.25227 11.4416C7.00855 11.7828 6.61852 11.9895 6.19933 11.9996C5.78014 12.0096 5.38063 11.8219 5.12081 11.4928L1.12086 6.42622C0.664571 5.84825 0.763209 5.00983 1.34117 4.55354C1.91914 4.09725 2.75757 4.19589 3.21385 4.77385L6.11399 8.44736L11.7489 0.558446C12.1769 -0.0407636 13.0097 -0.179551 13.6089 0.248456Z"
      fill="#F5F5FA"
    />
  </svg>
</template>
