<template>
  <svg
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.89141 2.89133C3.19349 2.58938 3.72213 2.49233 4.1321 2.90212L7.00188 5.77064L9.87171 2.90207C10.2817 2.49228 10.7995 2.57855 11.1124 2.89129C11.4145 3.19324 11.5116 3.72165 11.1016 4.13144L8.23179 7.00002L11.1016 9.86856C11.5116 10.2783 11.4145 10.8068 11.1124 11.1087C10.7995 11.4214 10.2816 11.5077 9.87167 11.0979L7.00188 8.22939L4.13213 11.0979C3.72216 11.5077 3.19352 11.4106 2.89144 11.1087C2.57857 10.7959 2.49226 10.2783 2.90223 9.86851L5.77197 7.00002L2.90219 4.13149C2.49223 3.7217 2.57853 3.20407 2.89141 2.89133Z"
      fill="white"
    />
  </svg>
</template>
