// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface StandoffOrderSettings
 */
export interface StandoffOrderSettings {
  /**
   *
   * @type {boolean}
   * @memberof StandoffOrderSettings
   */
  needRefund: boolean;
  /**
   *
   * @type {boolean}
   * @memberof StandoffOrderSettings
   */
  isSurplusEnabled: boolean;
  /**
   *
   * @type {string}
   * @memberof StandoffOrderSettings
   */
  skinName: string;
  /**
   *
   * @type {string}
   * @memberof StandoffOrderSettings
   */
  skinPicture: string;
  /**
   *
   * @type {number}
   * @memberof StandoffOrderSettings
   */
  rate: number;
  /**
   *
   * @type {number}
   * @memberof StandoffOrderSettings
   */
  marketFee: number;
}

/**
 * Check if a given object implements the StandoffOrderSettings interface.
 */
export function instanceOfStandoffOrderSettings(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "needRefund" in value;
  isInstance = isInstance && "isSurplusEnabled" in value;
  isInstance = isInstance && "skinName" in value;
  isInstance = isInstance && "skinPicture" in value;
  isInstance = isInstance && "rate" in value;
  isInstance = isInstance && "marketFee" in value;

  return isInstance;
}

export function StandoffOrderSettingsFromJSON(
  json: any,
): StandoffOrderSettings {
  return StandoffOrderSettingsFromJSONTyped(json, false);
}

export function StandoffOrderSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StandoffOrderSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    needRefund: json["need_refund"],
    isSurplusEnabled: json["is_surplus_enabled"],
    skinName: json["skin_name"],
    skinPicture: json["skin_picture"],
    rate: json["rate"],
    marketFee: json["market_fee"],
  };
}

export function StandoffOrderSettingsToJSON(
  value?: StandoffOrderSettings | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    need_refund: value.needRefund,
    is_surplus_enabled: value.isSurplusEnabled,
    skin_name: value.skinName,
    skin_picture: value.skinPicture,
    rate: value.rate,
    market_fee: value.marketFee,
  };
}
