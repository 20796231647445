// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { OrderItemRequest } from "./OrderItemRequest";
import {
  OrderItemRequestFromJSON,
  OrderItemRequestFromJSONTyped,
  OrderItemRequestToJSON,
} from "./OrderItemRequest";

/**
 *
 * @export
 * @interface BulldropOrderRequest
 */
export interface BulldropOrderRequest {
  /**
   *
   * @type {Array<OrderItemRequest>}
   * @memberof BulldropOrderRequest
   */
  products: Array<OrderItemRequest>;
  /**
   *
   * @type {string}
   * @memberof BulldropOrderRequest
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof BulldropOrderRequest
   */
  bulldropUserId: number;
}

/**
 * Check if a given object implements the BulldropOrderRequest interface.
 */
export function instanceOfBulldropOrderRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "products" in value;
  isInstance = isInstance && "bulldropUserId" in value;

  return isInstance;
}

export function BulldropOrderRequestFromJSON(json: any): BulldropOrderRequest {
  return BulldropOrderRequestFromJSONTyped(json, false);
}

export function BulldropOrderRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BulldropOrderRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    products: (json["products"] as Array<any>).map(OrderItemRequestFromJSON),
    email: !exists(json, "email") ? undefined : json["email"],
    bulldropUserId: json["bulldrop_user_id"],
  };
}

export function BulldropOrderRequestToJSON(
  value?: BulldropOrderRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    products: (value.products as Array<any>).map(OrderItemRequestToJSON),
    email: value.email,
    bulldrop_user_id: value.bulldropUserId,
  };
}
