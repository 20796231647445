<template>
  <NuxtLayout>
    <div :class="$style.error">
      <div :class="$style.wrapper">
        <div :class="$style.code">{{ error?.statusCode }}</div>
        <Heading1 :class="$style.h1"><span v-html="errorMessage" /></Heading1>
        <div :class="$style.btnWrapper">
          <PrimaryButton
            size="xl"
            :class="$style.link"
            @click="$router.push('/')"
            >{{ t("home.back_btn") }}</PrimaryButton
          >
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import Heading1 from "~/components/Shared/Heading/Heading1.vue";
import PrimaryButton from "~/components/Shared/Button/PrimaryButton.vue";

const error = useError();
const { t } = useI18n();

const errorMessage = computed(() => {
  if (error.value?.statusCode === 404) {
    return t("errors.not_found");
  }

  return t("errors.any_other");
});

useSeoMeta({
  title: `${error.value?.statusCode.toString()} - ${errorMessage.value}`,
  description: errorMessage.value,
});
</script>

<style module lang="scss">
.error {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 520px;
  margin-top: var(--spacing-6xl);

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 520px;
    width: 100%;
    height: 520px;
    background: url("~/assets/error-bg.png") center center no-repeat;
    background-size: contain;
    opacity: 0.25;
    z-index: -1;
  }
}

.h1 {
  color: var(--general-transparent-light-100);
  font-size: 64px;
  line-height: 100%;
  letter-spacing: -1.92px;
  font-weight: 500;

  @media screen and (max-width: $tablet) {
    font-size: 48px;
    line-height: 105%;
    letter-spacing: -1.44px;
  }

  @media screen and (max-width: $mobile) {
    font-size: 22px;
    line-height: 110%; /* 24.2px */
    letter-spacing: -0.44px;
  }
}

.wrapper {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.link {
  display: block;
  margin-top: var(--spacing-6xl);
}

.btnWrapper {
  display: flex;
  justify-content: center;
}

.code {
  color: var(--general-transparent-light-50);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: -0.036px;
}
</style>
