/* tslint:disable */
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";

export interface DocsApiSchemaRetrieveRequest {
  format?: SchemaRetrieveFormatEnum;
  lang?: SchemaRetrieveLangEnum;
}

/**
 *
 */
export class DocsApi extends runtime.BaseAPI {
  /**
   * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
   */
  async schemaRetrieveRaw(
    requestParameters: DocsApiSchemaRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<{ [key: string]: any }>> {
    const queryParameters: any = {};

    if (requestParameters.format !== undefined) {
      queryParameters["format"] = requestParameters.format;
    }

    if (requestParameters.lang !== undefined) {
      queryParameters["lang"] = requestParameters.lang;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/docs/schema/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
   */
  async schemaRetrieve(
    requestParameters: DocsApiSchemaRetrieveRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<{ [key: string]: any }> {
    const response = await this.schemaRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}

/**
 * @export
 */
export const SchemaRetrieveFormatEnum = {
  Json: "json",
  Yaml: "yaml",
} as const;
export type SchemaRetrieveFormatEnum =
  (typeof SchemaRetrieveFormatEnum)[keyof typeof SchemaRetrieveFormatEnum];
/**
 * @export
 */
export const SchemaRetrieveLangEnum = {
  En: "en",
  Ru: "ru",
} as const;
export type SchemaRetrieveLangEnum =
  (typeof SchemaRetrieveLangEnum)[keyof typeof SchemaRetrieveLangEnum];
