<template>
  <NuxtLayout>
    <NuxtPage />
    <ClientOnly>
      <Transition name="notification">
        <Notification v-if="notificationsStore.notificationIsRequired" />
      </Transition>
    </ClientOnly>
  </NuxtLayout>
</template>

<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { getAccess } from "./utils/tokens/getAccess";

const notificationsStore = useNotificationsStore();
const userStore = useUserStore();
if (getAccess() && !userStore.userData) {
  try {
    await userStore.getUser();
  } catch (e) {
    Sentry.captureException(e);
  }
}
</script>

<style lang="scss">
.online-chat-root-TalkMe #supportTrigger {
  @media screen and (max-width: $mobile) {
    right: 12px !important;
    bottom: 22px !important;

    svg {
      position: static !important;
    }

    .text.trigger-font-family {
      display: none !important;
    }
  }
}
</style>
